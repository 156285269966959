import { getGlobalParamsOptionsAsync } from '@/utils/common.js'

export const searchFormConfig = {
  formItems: [
    {
      field: 'terminalId',
      type: 'input',
      label: 'terminal.id'
    },
    {
      field: 'logType',
      type: 'select',
      label: 'terminal.log-type',
      options: [],
      isResolveGlobalParams: true,
      handler: () =>
        getGlobalParamsOptionsAsync('traceTypeList', {
          isNeedPreFix: false
        })
    },

    {
      field: 'creationDate',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange',
        format: 'YYYY-MM-DD',
        valueFormat: 'YYYY-MM-DD'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
