export const contentTableConfig = {
  // title: '('param.app-application-list')',
  propList: [
    {
      prop: 'terminalId',
      label: 'terminal.id'
    },
    {
      prop: 'logType',
      label: 'terminal.log-type'
    },
    {
      prop: 'remark',
      label: 'general.remark'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },
    {
      prop: 'operations',
      label: 'common.app-operation',
      width: '300',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true,
  showSelectColumn: true
}
