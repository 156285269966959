<template>
  <div class="terminals">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('general.router-trace-log')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @viewBtnClick="handleViewData"
      @pageInfoChange="pageInfoChangeAction"
    ></page-content>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/trace.form'
import { contentTableConfig } from './config/trace.table'

import { useRouter } from 'vue-router'
const router = useRouter()
// 表格基础配置项
const tableOptions = {
  moduleName: 'log', // 所属的模块
  pageName: 'traces', // 所属的页面
  contentTableConfig,
  isShowCreateSlot: false,
  isShowDeleteSlot: true,
  pageAction: 'log/getTraceListData', // 获取表格的Action
  pageCountGetter: 'log/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'log/pageListData',
  deleteSelectionAction: 'log/deleteMultiTrace' // 获取表格列表数据getters
}

const tableOptionsConfig = computed(() => tableOptions)

const pageContentRef = ref('')
const pageSearchRef = ref('')

const handleQueryItem = (items) => {
  const array = []
  const obj = {}
  Object.keys(items).forEach((key) => {
    if (items[key] !== '') array.push(key)
  })
  array.forEach((key) => {
    obj[key] = items[key]
  })
  return obj
}
const handleQueryClick = (queryInfo) => {
  const hasQueryInfo = !!Object.values(queryInfo).find((item) => item !== '')
  queryInfo = hasQueryInfo ? handleQueryItem(queryInfo) : {}
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}
const handleViewData = (id) => {
  router.push(`/terminal/view/${id}`)
}
</script>
